import { TriState } from "./Enums";

export class BuyerSearchParameters {
    pageNumber: number = 0;
    searchText: string = "";
    isDeleted: TriState = TriState.UseDefault;

    get showDeleted(): boolean {
        return this.isDeleted !== TriState.False;
    }
    set showDeleted(value: boolean) {
        this.isDeleted = value ? TriState.UseDefault : TriState.False;
    }
}