
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { Watch } from "vue-property-decorator";
    import store from "@/store/store";
    import router from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import { BuyerSearchParameters } from "@/model/BuyerSearchParameters";
    import { ILookupItem } from "@/model/LookupItem";
    import { Buyer, IBuyer } from "@/model/Buyer";
    import { User } from "@/model/User";
    import utils from "@/stuff/Utils";

    @Component({
        components: { ApiButton }
    })
    export default class Buyers extends Vue {

        //
        // -- lifecycle hooks (https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks)
        //

        mounted() {
            // load lookups - not using async / await - happy to just fire everything off and let Vue worry about rendering / re-rendering
            store.dispatch("loadCompanyTypeList");
            store.dispatch("loadCountryList");
            // don't search here - infinite-loading component will do that...
        }

        //
        // -- properties
        //

        private searchIsBusy: boolean = false;
        searchParameters = new BuyerSearchParameters();
        totalCount = -1; // needs to not be zero in order to trigger first search in infiniteLoadingHandler
        buyerList: Array<IBuyer> = [];
  
        //TODO - are we using these?
        private get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        private get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        private get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        private get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        //
        // -- watchers
        //

        @Watch("searchParameters.showDeleted")
        onShowDeletedChanged(value: boolean) {
            this.refreshSearch();
        }

        //
        // -- methods
        //

        async edit(buyerData: IBuyer) {
            router.push("/buyer/" + buyerData.id);
        }

        editNew() {
            router.push("/buyer/0");
        }

        // There's code in router.ts and store.ts to handle this
        // (this works across tabs becuase login is preserved in browser session storage
        // but storage is COPIED for new tab and only if it's a CHILD tab!)
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
        goToBuyerView(buyerData: IBuyer) {
            const routeData = this.$router.resolve({ name: "BuyerZoneHome" });
            
            // update session storage - child tab will inherit this data!
            const user = this.$store.state.signedInUser as User;
            user.buyerID = buyerData.id;
            store.dispatch("setSignedInUser", user);

            // so new tab can get buyer name, etc. without going off to the server
            window.sessionStorage.setItem("buyerData", JSON.stringify(buyerData));

            window.open(routeData.href, "_blank");
        }

        refreshSearch(event?: Event) {
            this.buyerList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.buyerList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.buyerList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            if (this.searchIsBusy) {
                console.log("Skipped search because busy...");
                return;
            }
            this.searchIsBusy = true;
            const response = await apiClient.post("/Api/Buyer/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.buyerList.push(...response.list.map((b: IBuyer) => new Buyer(b)));
            this.searchIsBusy = false;
        }
    }
